import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { DownloadExcel } from "react-excel-export";
import { useLocation } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Distrbutor_User } from "../../../services/api_function";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { CSVLink } from "react-csv";
import moment from "moment";

const DistrbutorData = () => {
  const [apiData, setApiData] = useState([]);
  const [apiDataFull, setApiDataFull] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [csvData, setcsvData] = useState([]);
  const [search, setSearch] = useState("");
  const [fullcsvData, setfullcsvData] = useState([]);
  const pageSize = 100;
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Distrbutor_User(
          currentPage,
          { searchQuery: search },
          token
        );
        setApiData(response?.data);
        const dataCvs=response?.data
        setApiDataFull(response?.csvData);
        const pages = Math.ceil(response?.totalUser / pageSize);
        setTotalPages(pages > 0 ? pages : 1);
        const ModifiedData = dataCvs.map((item) => {
            const {
              name,
              number,
              country,
              state,
              city,
              pincode,
              objective,
              createdAt,
            } = item;
            const Date = moment(createdAt).format("DD-MM-YYYY HH:mm");
            return {
              name,
              Contact: number,
              Country: country,
              State: state,
              City: city,
              Pincode: pincode,
              Objective: objective,
              Date,
            };
          });

        setcsvData(ModifiedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

  }, [currentPage, search]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const copyUser = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.info("Copied!");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };

  const columns = [
    { label: "Name", key: "name" },
    { label: "Contact", key: "Contact" },
    { label: "Country", key: "Country" },
    { label: "State", key: "State" },
    { label: "City", key: "City" },
    { label: "Pincode", key: "Pincode" },
    { label: "Objective", key: "Objective" },
    { label: "Date", key: "Date" },
  ];

  const handleDownload = async () => {
    try {
      const ModifiedData = apiDataFull.map((item) => {
        const {
          name,
          number,
          country,
          state,
          city,
          pincode,
          objective,
          createdAt,
        } = item;
        const Date = moment(createdAt).format("DD-MM-YYYY HH:mm");
        return {
          name,
          Contact: number,
          Country: country,
          State: state,
          City: city,
          Pincode: pincode,
          Objective: objective,
          Date,
        };
      });
      setfullcsvData(ModifiedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick = () => {
  handleDownload()
  };
  return (
    <Fragment>
      <Row>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div> */}
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Distrbutor List
              </Card.Title>
              <CSVLink
                data={csvData || []}
                headers={columns}
                filename={"Distributor.csv"}
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 20px",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginRight: "40px",
                }}
              >
                Page CSV
              </CSVLink>
              <CSVLink
                data={fullcsvData || []}
                headers={columns}
                filename={"Distributor.csv"}
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 20px",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginRight: "40px",
                }}
                onClick={handleClick} 
              >
                Full CSV
              </CSVLink>
            </Card.Header>

            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>NO</strong>
                    </th>

                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>Number</strong>
                    </th>
                    <th>
                      <strong>Country</strong>
                    </th>
                    <th>
                      <strong>State</strong>
                    </th>
                    <th>
                      <strong>City</strong>
                    </th>
                    <th>
                      <strong>Pin Code</strong>
                    </th>

                    <th>
                      <strong>Objective</strong>
                    </th>
                    <th>
                      <strong>Date & Time</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {apiData.length > 0 ? (
                    apiData.map((data, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{data?.name}</td>
                        <td>{data?.number}</td>
                        <td>{data?.country}</td>
                        <td>{data?.state}</td>
                        <td>{data?.city}</td>
                        <td>{data?.pincode}</td>
                        <td>{data?.objective}</td>
                        <td>{formatTimestamp(data?.createdAt)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No data found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DistrbutorData;
