import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { DownloadExcel } from "react-excel-export";
import { useLocation } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Refreal_withdraw } from "../../../services/api_function";
import reactSelect from "react-select";
import { numberToBytes } from "viem";
import { NotificationManager } from "react-notifications";

const ReferalWithdraw = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [search, setSearch] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const pageSize = 100;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = localStorage.getItem("userDetails");
        const parsedDetails = JSON.parse(userDetails);
        const token = parsedDetails.token;
        const response = await Refreal_withdraw(
          currentPage,
          { searchQuery: search },
          token
        );
        const { data, totalUser } = response;

        setFilteredData(data);
        const pages = Math.ceil(totalUser / pageSize);
        setTotalPages(pages > 0 ? pages : 1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, search]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const copyUser = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.info("Copied!");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Referral Withdraw
              </Card.Title>
            </Card.Header>

            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>
                      <strong>NO</strong>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <strong>Name</strong>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <strong>User</strong>
                    </th>

                    <th style={{ textAlign: "center" }}>
                      <strong>Amount</strong>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <strong>Transaction Id</strong>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <strong>Date & Time</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  { filteredData.length > 0 ? (
                    filteredData.map((data, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>
                          {(currentPage - 1) * pageSize + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>{data?.name}</td>
                        <td style={{ textAlign: "center" }}>
                          {data?.userAddress}
                          <i
                          className="far fa-copy"
                          onClick={() => copyUser(data?.userAddress)}
                          style={{ cursor: "pointer", marginLeft: "11px" }}
                        ></i>

                        </td>

                        <td style={{ textAlign: "center" }}>
                          {Number((data?.amount ?? 0) / 1e18).toFixed(6)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          <a
                            href={`https://arbiscan.io//tx/${data?.txnHash}`}
                            className="text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {data.txnHash
                              ? `${data.txnHash.slice(
                                  0,
                                  8
                                )}...${data.txnHash.slice(-5)}`
                              : ""}
                          </a>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {formatTimestamp(data?.createdAt ?? "-")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ReferalWithdraw;
